import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';
import AgricultoraLancamentos from '../components/AgricultoraLancamentos';

export default function Agricultora() {
    const model = 'agricultora';
    const basePath = '/cadernetas/';
    const objTitleField = 'nome';
    const [fieldsLayout, setFieldsLayout] = useState([
        {
            rows: [
                ['codigo', 'nome', 'apelido'],
                ['comunidade', 'telefone'],
                'organizacao',
                // 'anotacoes',
            ],
        },
    ]);
    const [hiddenFields, setHiddenFields] = useState([]);
    const params = useParams();
    /**
     * @type object
     */
    const context = useOutletContext();
    const {
        usuaria,
        loadSinglePageData,
        onSubmit,
        onEditModel,
        onDeleteModel,
        onEditRelatedModelSave,
        onDeleteRelatedModel,
    } = context;
    const pageBasics = context.pageState[0];
    const [pageForm, setPageForm] = context.pageFormState;
    const [optionsAC, setOptionsAC] = context.optionsACState;
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
        register,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const loadAllData = () => {
        const defaults = {};
        if (!usuaria.is_superuser) {
            if (usuaria.organizacoes.length === 1) {
                defaults['organizacao'] = usuaria.organizacoes[0].organizacao;
            }
        }
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        if (pageForm.forceReload) {
            loadAllData();
        }
    }, [pageForm.forceReload]);

    useEffect(() => {
        const schema = { ...pageForm.schema };
        if (pageForm.initialValues && !usuaria.is_superuser) {
            if (usuaria.organizacoes.length === 1 && usuaria.projetos.length === 0) {
                schema['organizacao'].disabled = true;
            }
        }
        setPageForm({
            schema,
        });
    }, [pageForm.initialValues]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Agricultora' : 'Editar dados básicos da Agricultora',
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            hiddenFields={hiddenFields}
                            control={control}
                            register={register}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            setOptionsAC={setOptionsAC}
                            forceReload={pageForm.forceReload}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                    {!isTmpId(pageBasics.id) && (
                        <AgricultoraLancamentos
                            id={pageBasics.id}
                            onEditModel={onEditModel}
                            onDeleteRelatedModel={onDeleteRelatedModel}
                            label="Caderneta"
                        />
                    )}
                </form>
            )}
        </>
    );
}
