import React from 'react';
import { useOutletContext } from 'react-router-dom';

import GenericPageList from '../pages/GenericPageList';

export default function QuestionarioList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    let columnFields = ['codigo', 'agricultora', 'usuaria', 'data', 'hora_inicial', 'hora_final'];
    if (usuaria.is_superuser || usuaria.organizacoes.length > 1) {
        columnFields.push('uf');
        columnFields.push('organizacao');
    }
    if (usuaria.is_superuser || usuaria.projetos.length > 1) {
        columnFields.push('projeto');
    }
    columnFields = [
        ...columnFields,
        'tipo_agricultora',
        'identidade_sociocultural',
        'propriedade_count',
        'parente_count',
        'acesso_agua_count',
        'acesso_politica_publica_count',
        'acesso_mercado_count',
        'organizacoes_economicas',
        'organizacoes_coletivas_display',
        'fonte_renda_familiar_count',
    ];
    const minWidthFields = null;
    const indexField = 'agricultora';
    const sumRows = {
        rows: [],
        severity: 'info',
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
        />
    );
}
