import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedAcessoMercado(props) {
    // Specific infos:
    const relatedModel = 'acesso_mercado';
    const columnFields = [
        'id',
        'acesso_mercado_tipo',
        'acesso_mercado_quem_produz',
        'agricultora_participa_producao',
        'acesso_mercado_quem_vende',
        'agricultora_participa_venda',
        'renda_por_semana',
        'responsavel_financeiro',
        'observacoes',
    ];
    const creatableFields = [
        'acesso_mercado_tipo',
        'acesso_mercado_quem_produz',
        'acesso_mercado_quem_vende',
    ];
    const indexField = 'acesso_mercado_tipo';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
