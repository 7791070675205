import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useOutletContext, Outlet, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import SidebarSettings from '../components/SidebarSettings';
import PAGES from 'src/pageTree';
import { useAPIWrapper } from 'drf-react-by-schema';

const shouldRemoveSuperUser = [
    'usuaria_groups',
    'usuaria_user_permissions',
    'super_projeto_equipe',
    'projeto_equipe',
    'organizacao_equipe',
    'caderneta',
    'questionario_organizacoes_economicas',
    'questionario_trabalho_extra',
    'questionario_propriedade',
    'questionario_parente',
    'organizacao_economica_produto',
    'organizacao_economica_produto_comercializacao',
    'organizacao_economica_organizacao_grupo_parceiro',
    'cnae_secao',
    'cnae_divisao',
    'cnae_grupo',
    'cnae_classe',
    'cnae_produto',
    'diocese',
    'regiao',
    'uf',
    'regiao_intermediaria',
    'regiao_imediata',
    'municipio',
    'trabalho_extra',
    'questionario_propriedade',
    'questionario_parente',
    'acesso_agua',
    'acesso_extrativismo',
    'acesso_tecnologia_social',
    'acesso_politica_publica',
    'acesso_mercado',
    'fonte_renda_familiar',
    'participacao_espaco_organizacao_coletiva',
    'organizacao_produto',
    'produto',
];

const shouldRemoveTecnica = [
    ...shouldRemoveSuperUser,
    'comunidade',
    'enquadramento_dap',
    'escolaridade',
    'estado_civil',
    'organizacao_faixa_renda',
    'produto_grupo',
    'identidade_sociocultural',
    'jornada_trabalho',
    'origem_etnica',
    'parentesco',
    'caderneta_lancamento_relacao',
    'tipo_agricultora',
    'fonte_renda',
    'acesso_mercado_tipo',
    'organizacao_tipo',
    'acesso_politica_publica_tipo',
    'unidade_medida',
    'vinculo_propriedade',
    'produto_processamento',
];

export default function CadernetasIndex() {
    const { getAllModels } = useAPIWrapper();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const pathParts = location.pathname.split('/');
    const page = pathParts[1] || null;
    const model = pathParts[2] || null;
    /**
     * @type object
     */
    const outletContext = useOutletContext();
    const context = {
        ...outletContext,
        model,
        indexFieldBasePath: `/${page}/${model}/`,
    };
    const { usuaria } = context;
    const [list, setList] = useState([]);

    useEffect(() => {
        getAllModels().then((models) => {
            const removeFromList = [];
            for (const [key, menu] of Object.entries(PAGES)) {
                if (menu.subPages) {
                    for (const [submenuKey, submenuTitle] of Object.entries(menu.subPages)) {
                        removeFromList.push(submenuKey);
                    }
                }
            }
            const removeFromListExtra = usuaria.is_superuser
                ? shouldRemoveSuperUser
                : shouldRemoveTecnica;
            const list = models.filter(
                (model) =>
                    !removeFromList.includes(model.model_name) &&
                    !removeFromListExtra.includes(model.model_name)
            );
            setList(list);
            context.handleLoading(false);
        });
    }, [location]);

    return (
        <>
            {list.length > 0 && <SidebarSettings items={list} model={model} />}
            <Box sx={{ paddingLeft: '320px' }}>
                <Outlet context={context} />
            </Box>
        </>
    );
}
