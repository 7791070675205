import React from 'react';

import GenericPageList from '../pages/GenericPageList';
import { Link, useOutletContext } from 'react-router-dom';

export default function ProjetoList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    const columnFields = [
        'nome',
        'sigla',
        'super_projeto',
        'organizacoes_display',
        'organizacoes_count',
        'agricultoras_count',
        'caderneta_lancamentos_count',
        'tecnicas_count',
        'usuarias_count',
    ];
    const minWidthFields = {
        agricultoras_count: 150,
        caderneta_lancamentos_count: 150,
        organizacoes_count: 150,
        organizacoes_display: 350,
        tecnicas_count: 200,
        usuarias_count: 200,
    };
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'organizacoes_count',
                prefix: 'Total de organizações: ',
                isCount: true,
            },
            {
                field: 'agricultoras_count',
                prefix: 'Total de agricultoras: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos nas cadernetas: ',
                isCount: true,
            },
            {
                field: 'tecnicas_count',
                prefix: 'Total de técnicas: ',
                isCount: true,
            },
            {
                field: 'usuarias_count',
                prefix: 'Total de coordenadoras de projeto: ',
                isCount: true,
            },
        ],
        severity: 'info',
    };
    const customColumnOperations = (column) => {
        if (column.field === 'super_projeto' && usuaria.is_superuser) {
            column.renderCell = (params) =>
                params.row.super_projeto ? (
                    <Link to={`/institucional/super_projeto/${params.row.super_projeto.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        return column;
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            customColumnOperations={customColumnOperations}
        />
    );
}
