import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { DataGridBySchemaEditable, DataTotals, useAPIWrapper } from 'drf-react-by-schema';

import { Layout } from '../styles';
import CONSTANTS from '../constants';

const ContentTable = ({
    data,
    relatedModel,
    model,
    id,
    indexField,
    indexFieldBasePath,
    addExistingModel,
    isEditable,
    onEditModel,
    onDeleteRelatedModel,
    finalCustomColumnOperations,
    setVisibleRows,
    isAutoHeight,
    hideFooterPagination,
    onProcessRow,
    setData,
    sumRows,
    visibleRows,
}) => (
    <>
        <Box sx={Layout.dataGridFixedHeight}>
            <DataGridBySchemaEditable
                data={data.data}
                columns={data.columns}
                schema={data.schema}
                model={relatedModel}
                modelParent={model}
                modelParentId={id}
                indexField={indexField}
                indexFieldBasePath={indexFieldBasePath}
                addExistingModel={addExistingModel}
                isEditable={isEditable}
                onEditModel={onEditModel}
                customColumnOperations={finalCustomColumnOperations}
                setVisibleRows={setVisibleRows}
                isAutoHeight={isAutoHeight}
                hideFooterPagination={hideFooterPagination}
                onProcessRow={onProcessRow}
                onDataChange={(newData) => {
                    setData({
                        ...data,
                        data: newData,
                    });
                }}
            />
        </Box>
        <DataTotals data={data.data} sumRows={sumRows} visibleRows={visibleRows} />
    </>
);

export default function GenericRelatedModelList(props) {
    const { getGenericModelList } = useAPIWrapper();
    const [data, setData] = useState(null);
    const [visibleRows, setVisibleRows] = useState([]);
    const [hideFooterPagination, setHideFooterPagination] = useState(false);
    const {
        id,
        relatedModel,
        columnFields,
        creatableFields,
        hiddenFields,
        usuaria = null,
        minWidthFields = null,
        indexField = '',
        addExistingModel = '',
        label,
        onEditModel,
        onDeleteRelatedModel,
        onProcessRow = null,
        sumRows = null,
        customColumnOperations = null,
        isEditable = true,
        isAutoHeight = false,
        isInBatches = true,
        noCardWrapper = false,
    } = props;
    let indexFieldBasePath = props.indexFieldBasePath;

    const location = useLocation();
    const locationParts = location.pathname.split('/');
    const model = locationParts.length > 3 ? locationParts[2] : locationParts[1];

    const finalCustomColumnOperations = (column) => {
        if (minWidthFields) {
            if (Object.prototype.hasOwnProperty.call(minWidthFields, column.field)) {
                column.minWidth = minWidthFields[column.field];
            }
        }

        if (customColumnOperations) {
            return customColumnOperations(column);
        }

        return column;
    };

    const loadObjectList = async () => {
        if (!indexFieldBasePath) {
            indexFieldBasePath = locationParts.length > 1 ? `/${locationParts[1]}/` : '';
        }
        const loadParams = {
            model,
            id,
            relatedModel,
            indexFieldBasePath,
            columnFields,
            creatableFields,
            hiddenFields,
            usuaria,
            isInBatches,
        };
        const loadedData = await getGenericModelList(loadParams);
        if (loadedData) {
            setData(loadedData);
            if (isInBatches && loadedData.data.length === CONSTANTS.FIRST_BATCH_LENGTH) {
                setHideFooterPagination(true);
                getGenericModelList({
                    ...loadParams,
                    loadedSchema: loadedData.schema,
                }).then((lastBatchData) => {
                    if (lastBatchData) {
                        setData({
                            ...loadedData,
                            data: [...loadedData.data, ...lastBatchData.data],
                        });
                        setHideFooterPagination(false);
                    }
                });
            }
            return;
        }
        console.log('error retrieving data!');
    };

    useEffect(() => {
        loadObjectList();
    }, []);

    return (
        <>
            {data && data.columns && (
                <>
                    {noCardWrapper ? (
                        <ContentTable
                            data={data}
                            model={model}
                            relatedModel={relatedModel}
                            id={id}
                            indexField={indexField}
                            indexFieldBasePath={indexFieldBasePath}
                            addExistingModel={addExistingModel}
                            isEditable={isEditable}
                            onEditModel={onEditModel}
                            onDeleteRelatedModel={onDeleteRelatedModel}
                            finalCustomColumnOperations={finalCustomColumnOperations}
                            setVisibleRows={setVisibleRows}
                            isAutoHeight={isAutoHeight}
                            hideFooterPagination={hideFooterPagination}
                            onProcessRow={onProcessRow}
                            setData={setData}
                            sumRows={sumRows}
                            visibleRows={visibleRows}
                        />
                    ) : (
                        <Card sx={Layout.formCard}>
                            <CardHeader title={label} />
                            <CardContent>
                                <ContentTable
                                    data={data}
                                    model={model}
                                    relatedModel={relatedModel}
                                    id={id}
                                    indexField={indexField}
                                    indexFieldBasePath={indexFieldBasePath}
                                    addExistingModel={addExistingModel}
                                    isEditable={isEditable}
                                    onEditModel={onEditModel}
                                    onDeleteRelatedModel={onDeleteRelatedModel}
                                    finalCustomColumnOperations={finalCustomColumnOperations}
                                    setVisibleRows={setVisibleRows}
                                    isAutoHeight={isAutoHeight}
                                    hideFooterPagination={hideFooterPagination}
                                    onProcessRow={onProcessRow}
                                    setData={setData}
                                    sumRows={sumRows}
                                    visibleRows={visibleRows}
                                />
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}

GenericRelatedModelList.propTypes = {
    id: PropTypes.any,
    relatedModel: PropTypes.string,
    columnFields: PropTypes.array,
    creatableFields: PropTypes.array,
    hiddenFields: PropTypes.array,
    usuaria: PropTypes.object,
    minWidthFields: PropTypes.object,
    indexField: PropTypes.string,
    addExistingModel: PropTypes.string,
    label: PropTypes.string,
    onEditModel: PropTypes.func,
    onDeleteRelatedModel: PropTypes.func,
    onProcessRow: PropTypes.func,
    sumRows: PropTypes.object,
    customColumnOperations: PropTypes.func,
    isEditable: PropTypes.bool,
    indexFieldBasePath: PropTypes.string,
    isAutoHeight: PropTypes.bool,
    isInBatches: PropTypes.bool,
};
