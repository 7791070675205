import React from 'react';
import { Link } from 'react-router-dom';
import { GenericRelatedModelList } from 'drf-react-by-schema';

export default function AgricultoraLancamentos(props) {
    // Specific infos:
    const model = 'agricultora';
    const relatedModel = 'caderneta_lancamento';
    const columnFields = [
        'id',
        'data',
        'produto',
        'produto_classe',
        'produto_grupo',
        'produto_origem',
        'unidade_medida',
        'quantidade',
        'valor_total',
        'valor_unitario',
        'caderneta_lancamento_relacao',
    ];
    const creatableFields = ['produto', 'unidade_medida', 'caderneta_lancamento_relacao'];
    const indexField = 'produto';
    const minWidthFields = null;
    const addExistingModel = '';
    const onProcessRow = (row) => {
        row.valor_unitario =
            row.quantidade && row.valor_total ? row.valor_total / row.quantidade : null;
        return row;
    };
    const sumRows = {
        rows: [
            {
                field: 'valor_total',
                prefix: 'Total: R$ ',
            },
        ],
    };

    return (
        <GenericRelatedModelList
            model={model}
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            paginationMode="server"
            LinkComponent={Link}
            {...props}
        />
    );
}
