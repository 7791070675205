import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, useOutletContext, Outlet } from 'react-router-dom';

import { GenericModelList } from 'drf-react-by-schema';

export default function GenericPageList({
    columnFields,
    hiddenFields = [],
    sumRows = null,
    minWidthFields = null,
    customColumnOperations = null,
    indexField,
    isAutoHeight = true,
    customLinkDestination = null,
    hasHeader = false,
    customActions = {},
    forceReload = false,
}) {
    /**
     * @type object
     */
    const context = useOutletContext();
    const { model, indexFieldBasePath } = context;
    const pageBasics = context.pageState[0];
    const params = useParams();

    return (
        <>
            <Outlet context={context} />
            {!pageBasics.id && !params.objId && (
                <GenericModelList
                    columnFields={columnFields}
                    hiddenFields={hiddenFields}
                    minWidthFields={minWidthFields}
                    indexField={indexField}
                    sumRows={sumRows}
                    customColumnOperations={customColumnOperations}
                    isAutoHeight={isAutoHeight}
                    customLinkDestination={customLinkDestination}
                    model={model}
                    indexFieldBasePath={indexFieldBasePath}
                    LinkComponent={Link}
                    hasHeader={hasHeader}
                    paginationMode="server"
                    customActions={customActions[model]}
                    isEditable={true}
                    forceReload={forceReload}
                />
            )}
        </>
    );
}

GenericPageList.propTypes = {
    columnFields: PropTypes.array,
    hiddenFields: PropTypes.array,
    indexField: PropTypes.string,
    sumRows: PropTypes.object,
    minWidthFields: PropTypes.object,
    customColumnOperations: PropTypes.func,
    isAutoHeight: PropTypes.bool,
    customLinkDestination: PropTypes.func,
};
