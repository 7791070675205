import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { hasJWT } from 'drf-react-by-schema';

export default function AuthRoute() {
    if (hasJWT()) {
        return <Outlet />;
    }
    return <Navigate to={'/login'} replace />;
}
