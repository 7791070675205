import React, { useState, useReducer, useEffect } from 'react';
import '../styles/globals.css';
import '../styles/login.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';
import { Layout } from '../styles';
import { useAPIWrapper } from 'drf-react-by-schema';

export default function Login() {
    const [status, setStatus] = useState(null);
    const initialPayload = {
        username: null,
        password: null,
    };
    const reducer = (state, newState) => {
        return { ...state, ...newState };
    };
    /**
     * @type object
     */
    const [payload, setPayload] = useReducer(reducer, initialPayload);
    const { handleLoading, updateUsuaria, loginByPayload } = useAPIWrapper();
    const handleSubmit = () => {
        loginByPayload(payload).then((success) => {
            if (success) {
                setStatus('loggedIn');
                updateUsuaria();
                return;
            }
            setStatus('failed');
        });
    };

    useEffect(() => {
        setStatus(null);
        setPayload(initialPayload);
        handleLoading(false);
    }, []);

    return (
        <>
            {status === 'loggedIn' ? (
                <Navigate to={'/'} replace />
            ) : (
                <div className="login-container">
                    <section className="form">
                        <div className="divForm">
                            <h1 className="title">
                                Cadernetas<br></br>Agroecológicas
                            </h1>
                            <h3>Bem vinda(o) ao sistema da Caderneta Agroecológica!</h3>
                            <p>Aqui você pode registrar e consultar informações da caderneta.</p>
                            <span>Faça seu login informando email e senha!</span>
                            {status === 'failed' && (
                                <Alert severity="error">
                                    A autenticação falhou. Confira seu nome de usuária ou senha e
                                    tente novamente.
                                </Alert>
                            )}
                            <TextField
                                autoFocus
                                margin="dense"
                                id="username"
                                label="Nome de usuária(o)"
                                type="email"
                                fullWidth
                                variant="outlined"
                                sx={{ backgroundColor: '#FFFFFFBB' }}
                                onChange={(e) => {
                                    setPayload({ username: e.target.value });
                                }}
                            />
                            <TextField
                                margin="dense"
                                id="password"
                                label="Senha"
                                type="password"
                                fullWidth
                                variant="outlined"
                                sx={{ backgroundColor: '#FFFFFFBB' }}
                                onKeyPress={(e) => {
                                    const code = e.keyCode ? e.keyCode : e.which;
                                    if (code === 13) {
                                        handleSubmit();
                                    }
                                }}
                                onChange={(e) => {
                                    setPayload({ password: e.target.value });
                                }}
                            />
                            <Button
                                variant="contained"
                                size="large"
                                sx={Layout.fullWidthButton}
                                onClick={handleSubmit}>
                                Entrar
                            </Button>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}
