import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedParticipacaoEspacoOrganizacaoColetiva(props) {
    // Specific infos:
    const relatedModel = 'participacao_espaco_organizacao_coletiva';
    const columnFields = ['id', 'espaco_organizacao_coletiva', 'excerce_lideranca'];
    const creatableFields = ['espaco_organizacao_coletiva'];
    const indexField = 'espaco_organizacao_coletiva';
    const minWidthFields = { exerce_lideranca: 300 };
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
