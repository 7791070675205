import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function ProjetoRelatedUsuaria(props) {
    // Specific infos:
    const relatedModel = 'projeto_equipe';
    const columnFields = ['id', 'usuaria', 'is_admin'];
    const creatableFields = [];
    const minWidthFields = { is_admin: 200 };
    const indexField = 'usuaria';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            minWidthFields={minWidthFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
