import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedFonteRendaFamiliar(props) {
    // Specific infos:
    const relatedModel = 'fonte_renda_familiar';
    const columnFields = ['id', 'fonte_renda', 'classificacao_importancia'];
    const creatableFields = ['fonte_renda'];
    const indexField = 'fonte_renda';
    const minWidthFields = { classificacao_importancia: 300 };
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
