import React from 'react';

import GenericPageList from '../pages/GenericPageList';

export default function SuperProjetoList(props) {
    const columnFields = [
        'id',
        'nome',
        'agricultoras_count',
        'caderneta_lancamentos_count',
        'projetos_display',
        'projetos_count',
        'organizacoes_display',
        'organizacoes_count',
        'tecnicas_count',
        'usuarias_count',
    ];
    const minWidthFields = {
        projetos_display: 250,
        organizacoes_display: 400,
        organizacoes_count: 150,
        caderneta_lancamentos_count: 150,
        tecnicas_count: 150,
        usuarias_count: 150,
    };
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'projetos_count',
                prefix: 'Total de projetos: ',
                isCount: true,
            },
            {
                field: 'organizacoes_count',
                prefix: 'Total de organizações nos projetos: ',
                isCount: true,
            },
            {
                field: 'tecnicas_count',
                prefix: 'Total de técnicas nas organizações dos projetos: ',
                isCount: true,
            },
            {
                field: 'agricultoras_count',
                prefix: 'Total de agricultoras: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos nas cadernetas: ',
                isCount: true,
            },
            {
                field: 'usuarias_count',
                prefix: 'Total de Coordenadoras e integrantes dos projetos: ',
                isCount: true,
            },
        ],
        severity: 'info',
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
        />
    );
}
