import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import UndoIcon from '@mui/icons-material/Undo';
import { isTmpId } from 'drf-react-by-schema';

import { Layout } from '../styles';

export default function FormButtons(props) {
    const {
        title,
        formDisabled,
        onDeleteModel = null,
        objId = null,
        cancelBtn = null,
        sx = {},
        bottom = false,
        borderBottom = true,
        saveAndContinue = true,
        backTarget = -1,
    } = props;
    const navigate = useNavigate();
    if (bottom && borderBottom && objId && objId !== 'novo') {
        sx.mb = 5;
        sx.pb = 2;
        sx.borderBottom = 'solid 1px #aaa';
    }
    return (
        <Box sx={{ ...Layout.flexRowGrow, ...sx }}>
            {bottom ? (
                <Button
                    variant="contained"
                    type="button"
                    color="error"
                    size="small"
                    disabled={isTmpId(objId) || !onDeleteModel}
                    onClick={onDeleteModel}>
                    Apagar
                </Button>
            ) : title ? (
                <Typography variant="h5">{title}</Typography>
            ) : (
                <Box />
            )}
            <Box sx={Layout.flexRow}>
                {cancelBtn ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={cancelBtn}>
                        Cancelar
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                            navigate(backTarget);
                        }}>
                        Voltar
                    </Button>
                )}
                {saveAndContinue && (
                    <>
                        <Button
                            variant="contained"
                            name="createNewOnSave"
                            type="submit"
                            color="secondary"
                            size="small"
                            sx={{ ml: 1 }}
                            disabled={formDisabled}>
                            Salvar e criar novo
                        </Button>
                        <Button
                            variant="contained"
                            name="stayOnSave"
                            type="submit"
                            color="secondary"
                            size="small"
                            sx={{ ml: 1 }}
                            disabled={formDisabled}>
                            Salvar e continuar
                        </Button>
                    </>
                )}
                <Button
                    variant="contained"
                    name="exitOnSave"
                    type="submit"
                    color="primary"
                    size="small"
                    sx={{ ml: 1 }}
                    disabled={formDisabled}>
                    Salvar
                </Button>
            </Box>
        </Box>
    );
}

FormButtons.propTypes = {
    title: PropTypes.string,
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDeleteModel: PropTypes.func,
    formDisabled: PropTypes.bool,
    cancelBtn: PropTypes.func,
    bottom: PropTypes.bool,
    borderBottom: PropTypes.bool,
    saveAndContinue: PropTypes.bool,
    sx: PropTypes.object,
};
