import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedAcessoPoliticaPublica(props) {
    // Specific infos:
    const relatedModel = 'acesso_politica_publica';
    const columnFields = [
        'id',
        'acesso_politica_publica_tipo',
        'acesso_encerrado',
        'periodo_inicio',
        'periodo_fim',
        'responsavel_financeiro',
    ];
    const creatableFields = ['acesso_politica_publica_tipo'];
    const indexField = 'acesso_politica_publica_tipo';
    const minWidthFields = { responsavel_financeiro: 200 };
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
