import React, { useEffect } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';
import QuestionarioRelatedPropriedade from '../components/QuestionarioRelatedPropriedade';
import QuestionarioRelatedParente from '../components/QuestionarioRelatedParente';
import QuestionarioRelatedAcessoAgua from '../components/QuestionarioRelatedAcessoAgua';
import QuestionarioRelatedAcessoPoliticaPublica from '../components/QuestionarioRelatedAcessoPoliticaPublica';
import QuestionarioRelatedAcessoMercado from '../components/QuestionarioRelatedAcessoMercado';
import QuestionarioRelatedFonteRendaFamiliar from '../components/QuestionarioRelatedFonteRendaFamiliar';
import QuestionarioRelatedParticipacaoEspacoOrganizacaoColetiva from '../components/QuestionarioRelatedParticipacaoEspacoOrganizacaoColetiva';
import QuestionarioRelatedTrabalhoExtra from '../components/QuestionarioRelatedTrabalhoExtra';
import QuestionarioRelatedAcessoTecnologiaSocial from '../components/QuestionarioRelatedAcessoTecnologiaSocial';
import QuestionarioRelatedAcessoExtrativismo from '../components/QuestionarioRelatedAcessoExtrativismo';
import QuestionarioRelatedOrganizacaoEconomica from 'src/components/QuestionarioRelatedOrganizacaoEconomica';

export default function Questionario() {
    const params = useParams();
    /**
     * @type object
     */
    const context = useOutletContext();
    const {
        usuaria,
        loadSinglePageData,
        onSubmit,
        onEditModel,
        onDeleteModel,
        onEditRelatedModelSave,
        onDeleteRelatedModel,
    } = context;
    const pageBasics = context.pageState[0];
    const [pageForm, setPageForm] = context.pageFormState;
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const model = 'questionario';
    const basePath = '/cadernetas/';
    const objTitleField = 'agricultora';
    const fieldsLayoutAll = [
        {
            title: 'Informações sobre a Pesquisa',
            rows: [['codigo', 'agricultora'], ['usuaria'], ['data', 'hora_inicial', 'hora_final']],
        },
        {
            title: '1. Informações sobre a Agricultora',
            rows: [
                ['data_nascimento', 'contato'],
                ['situacao_moradia', 'origem_etnica'],
                ['escolaridade', 'tipo_agricultora', 'identidade_sociocultural'],
                ['estuda'],
                ['possui_cadastro_ambiental_rural'],
                ['trabalha_fora'],
                ['possui_dap'],
                ['enquadramento_dap'],
            ],
        },
        {
            title: '1.1 Trabalho Extra',
            CustomElement: (
                <QuestionarioRelatedTrabalhoExtra
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Propriedade"
                />
            ),
        },
        {
            title: '2.1 Informações sobre a(s) propriedade(s) da família',
            CustomElement: (
                <QuestionarioRelatedPropriedade
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Propriedade"
                />
            ),
        },
        {
            title: '2.2 Informações sobre a família',
            CustomElement: (
                <QuestionarioRelatedParente
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Parentes"
                />
            ),
        },
        {
            title: '3.1 Acesso à água',
            CustomElement: (
                <QuestionarioRelatedAcessoAgua
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Acesso à água"
                />
            ),
        },
        {
            title: '3.2 Bens naturais do extrativismo (vegetal e animal) para a manutenção da família',
            CustomElement: (
                <QuestionarioRelatedAcessoExtrativismo
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Acesso a Tecnologias Sociais"
                />
            ),
        },
        {
            title: '3.3 Acesso a Tecnologias Sociais',
            CustomElement: (
                <QuestionarioRelatedAcessoTecnologiaSocial
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Acesso a Tecnologias Sociais"
                />
            ),
        },
        {
            title: '4. Acesso a Políticas Públicas',
            CustomElement: (
                <QuestionarioRelatedAcessoPoliticaPublica
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Acesso a Políticas Públicas"
                />
            ),
        },
        {
            title: '5. Acesso a Mercados',
            CustomElement: (
                <QuestionarioRelatedAcessoMercado
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Acesso a Políticas Públicas"
                />
            ),
        },
        /*{
            title: '6. Organizações econômicas',
            CustomElement: (
                <QuestionarioRelatedOrganizacaoEconomica
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Espaços de Organização Coletiva"
                />
            ),
        },*/
        {
            title: '6. Organizações econômicas',
            rows: [['organizacoes_economicas']],
        },
        {
            title: '7. Participação em Espaços de Organização Coletiva',
            CustomElement: (
                <QuestionarioRelatedParticipacaoEspacoOrganizacaoColetiva
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Espaços de Organização Coletiva"
                />
            ),
        },
        {
            title: '8. Fontes de Renda Familiar',
            CustomElement: (
                <QuestionarioRelatedFonteRendaFamiliar
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Fontes de Renda Familiar"
                />
            ),
        },
        {
            title: 'Percepções',
            rows: ['descricao_agroecologia', 'descricao_feminismo'],
        },
    ];
    const fieldsLayout = params.objId !== 'novo' ? fieldsLayoutAll : [fieldsLayoutAll[0]];

    const loadAllData = () => {
        const defaults = { usuaria: usuaria };
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        const schema = { ...pageForm.schema };
        if (
            pageForm.initialValues &&
            !usuaria.is_superuser &&
            !usuaria.is_admin_some_projeto_or_organizacao
        ) {
            schema.usuaria.disabled = true;
        }
        setPageForm({
            schema,
        });
    }, [pageForm.initialValues]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Novo Questionário' : 'Editar Questionário',
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            control={control}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={null}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}
