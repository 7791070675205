import React from 'react';

import GenericPageList from '../pages/GenericPageList';
import { Link, useOutletContext } from 'react-router-dom';

export default function OrganizacaoList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    let columnFields = ['sigla', 'nome'];
    if (usuaria.is_superuser || usuaria.projetos.length > 1) {
        columnFields.push('projeto');
        columnFields.push('super_projeto');
    }
    columnFields = [
        ...columnFields,
        'agricultoras_count',
        'caderneta_lancamentos_count',
        'usuarias_count',
    ];
    const minWidthFields = null;
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'agricultoras_count',
                prefix: 'Total de agricultoras: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos nas cadernetas: ',
                isCount: true,
            },
            {
                field: 'usuarias_count',
                prefix: 'Total de técnicas: ',
                isCount: true,
            },
        ],
        severity: 'info',
    };
    const customColumnOperations = (column) => {
        if (column.field === 'projeto' && usuaria.is_superuser) {
            column.renderCell = (params) =>
                params.row.projeto ? (
                    <Link to={`/institucional/projeto/${params.row.projeto.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        if (column.field === 'super_projeto' && usuaria.is_superuser) {
            column.renderCell = (params) =>
                params.row.super_projeto ? (
                    <Link to={`/institucional/super_projeto/${params.row.super_projeto.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        return column;
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            customColumnOperations={customColumnOperations}
        />
    );
}
