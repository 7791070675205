import React, { useEffect } from 'react';
import {
    useNavigate,
    useLocation,
    useOutletContext,
    Outlet,
    Link,
    useParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Layout } from '../styles';

export default function CadernetasIndex(props) {
    const defaultModel = 'agricultora';
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const pathParts = location.pathname.split('/');
    const page = pathParts[1] || null;
    const model = pathParts[2] || null;
    /**
     * @type object
     */
    const outletContext = useOutletContext();
    const context = {
        ...outletContext,
        model,
        indexFieldBasePath: `/${page}/${model}/`,
    };
    const pageBasics = context.pageState[0];

    // const handleTabsChange = (event, newModel) => {
    //     navigate(`${newModel}`);
    // };

    useEffect(() => {
        if (!model) {
            navigate(defaultModel);
        }
    }, [location]);

    return (
        <>
            {model && !params.objId && <></>}
            <Outlet context={context} />
        </>
    );
}
