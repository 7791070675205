import React, { useEffect, useRef, useState } from 'react';
import { useAPIWrapper, slugToCamelCase } from 'drf-react-by-schema';
import MergeTwoToneIcon from '@mui/icons-material/MergeTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import GenericPageList from '../pages/GenericPageList';

export default function GenericSettingsList() {
    const { postData, getAutoComplete, onTriggerSnackBar } = useAPIWrapper();
    const [optionsAC, setOptionsAC] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [forceReload, setForceReload] = useState(false);
    const changingForceReload = useRef(false);
    const hiddenFields = ['id', 'label', 'slug'];
    const minWidthFields = null;
    const indexField = 'nome';
    const sumRows = {
        rows: [],
        severity: 'info',
    };
    const customActions = {
        unidade_medida: [
            {
                key: 'unidade_medida',
                label: 'Mesclar',
                handleClick: (item) => {
                    setSelectedRow({
                        model: 'unidade_medida',
                        origin: item,
                        title: 'Unidade de Medida',
                        conversionFactor: {
                            field: 'quantidade',
                            value: 1,
                        },
                    });
                },
                icon: <MergeTwoToneIcon />,
            },
        ],
    };

    const handleDialogClose = () => {
        setSelectedRow(null);
    };

    const handleMergeModel = async () => {
        if (selectedRow) {
            const data = {
                model: slugToCamelCase(selectedRow.model, true),
                origin_id: selectedRow.origin.id,
                target_id: selectedRow.target.id,
            };
            if (selectedRow.conversionFactor) {
                data.conversion_factor_field = selectedRow.conversionFactor.field;
                data.conversion_factor_value = parseFloat(
                    selectedRow.conversionFactor.value.replace(',', '.')
                );
            }
            const response = await postData({ path: 'merge', data });
            onTriggerSnackBar({
                severity: response && response.Success ? 'success' : 'error',
                msg:
                    response && response.Success
                        ? 'Mesclagem realizada com sucesso!'
                        : 'Houve uma falha na mesclagem...',
            });
            setSelectedRow(null);
            setForceReload(true);
            changingForceReload.current = true;
        }
    };

    const updateOptionsAC = async (model) => {
        const newOptionsAC = await getAutoComplete(model);
        if (newOptionsAC) {
            setOptionsAC(newOptionsAC);
            return;
        }
        console.log(`'Erro atualizando optionsAC do modelo ${model}`);
    };

    useEffect(() => {
        if (selectedRow && selectedRow.model) {
            updateOptionsAC(selectedRow.model);
            return;
        }
        setOptionsAC(null);
    }, [selectedRow?.model]);

    useEffect(() => {
        if (changingForceReload.current && forceReload) {
            setForceReload(false);
            changingForceReload.current = false;
        }
    }, [forceReload]);

    return (
        <>
            <GenericPageList
                hiddenFields={hiddenFields}
                indexField={indexField}
                sumRows={sumRows}
                minWidthFields={minWidthFields}
                hasHeader={true}
                customActions={customActions}
                forceReload={forceReload}
            />
            <Dialog open={selectedRow !== null} onClose={handleDialogClose}>
                <DialogTitle>
                    Mesclar {selectedRow ? selectedRow.title : ''} "
                    {selectedRow && selectedRow.origin ? selectedRow.origin.label : ''}"
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3 }}>
                        <form onSubmit={handleMergeModel}>
                            <Autocomplete
                                options={optionsAC || []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={selectedRow ? selectedRow.title : ''}
                                    />
                                )}
                                value={
                                    selectedRow && selectedRow.target ? selectedRow.target : null
                                }
                                onChange={(_, value) => {
                                    setSelectedRow((currSelectedRow) => {
                                        return {
                                            ...currSelectedRow,
                                            target: value,
                                        };
                                    });
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                            {selectedRow && selectedRow.conversionFactor && (
                                <TextField
                                    label="Fator de conversão"
                                    value={selectedRow.conversionFactor.value}
                                    onChange={({ target }) => {
                                        setSelectedRow((currSelectedRow) => {
                                            return {
                                                ...currSelectedRow,
                                                conversionFactor: {
                                                    ...currSelectedRow.conversionFactor,
                                                    value: target.value,
                                                },
                                            };
                                        });
                                    }}
                                    fullWidth={true}
                                    sx={{ mt: 4 }}
                                />
                            )}
                        </form>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button onClick={handleMergeModel}>Aplicar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
