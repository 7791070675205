import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SignJWT } from 'jose';
import { Layout } from '../styles';
import CONSTANTS from '../constants';

export default function MetabaseAppEmbed({
    className,
    style = Layout.metabaseAppEmbed,
    payload,
    onFrame = null,
    hidden = false,
    titled = true,
    bordered = true,
}) {
    const [frame, setFrame] = useState({ iframeUrl: null });

    const loadFrame = async () => {
        const mbSiteUrl = CONSTANTS.METABASE.URL;
        const mbSecretKey = new TextEncoder().encode(CONSTANTS.METABASE.SECRET_KEY);

        payload.exp = Math.round(Date.now() / 1000) + 10 * 60; // 10 minute expiration
        const token = await new SignJWT(payload)
            .setProtectedHeader({ alg: 'HS256' })
            .setExpirationTime('2h')
            .sign(mbSecretKey);

        const iframeUrl = `${mbSiteUrl}/embed/dashboard/${token}#bordered=${bordered}&titled=${titled}`;
        setFrame({ iframeUrl });
    };

    const handleFrameLoad = () => {
        if (onFrame) {
            onFrame(false);
        }
    };

    useEffect(() => {
        loadFrame();
    }, [payload]);

    return (
        <>
            {frame.iframeUrl && (
                <iframe
                    hidden={hidden}
                    src={frame.iframeUrl}
                    className={className}
                    onLoad={handleFrameLoad}
                    style={{
                        border: 'none',
                        width: '100%',
                        ...style,
                    }}
                />
            )}
        </>
    );
}

MetabaseAppEmbed.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onFrame: PropTypes.func,
    payload: PropTypes.object,
    hidden: PropTypes.bool,
};
