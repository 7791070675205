import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedAcessoTecnologiaSocial(props) {
    // Specific infos:
    const relatedModel = 'acesso_tecnologia_social';
    const columnFields = ['id', 'tecnologia_social'];
    const creatableFields = ['tecnologia_social'];
    const indexField = 'tecnologia_social';
    const minWidthFields = null;
    const addExistingModel = 'propriedade';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
