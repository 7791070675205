import React, { useEffect } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';
import OrganizacaoRelatedUsuaria from '../components/OrganizacaoRelatedUsuaria';
import OrganizacaoRelatedAgricultora from '../components/OrganizacaoRelatedAgricultora';

export default function Organizacao() {
    const params = useParams();
    /**
     * @type object
     */
    const context = useOutletContext();
    const {
        usuaria,
        loadSinglePageData,
        onSubmit,
        onEditModel,
        onDeleteModel,
        onDeleteRelatedModel,
    } = context;
    const pageBasics = context.pageState[0];
    const [pageForm, setPageForm] = context.pageFormState;
    const optionsAC = context.optionsACState[0];
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const model = 'organizacao';
    const objTitleField = 'nome';
    const basePath = '/institucional/';
    const fieldsLayoutAll = [
        {
            rows: [['nome', 'sigla'], 'projeto', 'anotacoes'],
        },
        {
            title: 'Vincular técnicas e coordenadoras da organização',
            CustomElement: (
                <OrganizacaoRelatedUsuaria
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label=""
                />
            ),
        },
        {
            title: 'Agricultoras',
            CustomElement: (
                <OrganizacaoRelatedAgricultora
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label=""
                />
            ),
        },
    ];
    const fieldsLayout = params.objId !== 'novo' ? fieldsLayoutAll : [fieldsLayoutAll[0]];

    const loadAllData = () => {
        const defaults = {};
        if (!usuaria.is_superuser) {
            if (usuaria.projetos.length === 1) {
                defaults['projeto'] = usuaria.projetos[0].projeto;
            }
        }
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            defaults,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    useEffect(() => {
        const schema = { ...pageForm.schema };
        if (pageForm.initialValues && !usuaria.is_superuser) {
            if (usuaria.projetos.length === 1) {
                schema['projeto'].disabled = true;
            }
        }
        setPageForm({
            schema,
        });
    }, [pageForm.initialValues]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Organização' : 'Editar Organização',
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            control={control}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}
