import React, { useEffect } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';
import SuperProjetoRelatedProjeto from '../components/SuperProjetoRelatedProjeto';

export default function SuperProjeto() {
    const params = useParams();
    /**
     * @type object
     */
    const context = useOutletContext();
    const { loadSinglePageData, onSubmit, onEditModel, onDeleteModel, onDeleteRelatedModel } =
        context;
    const pageBasics = context.pageState[0];
    const pageForm = context.pageFormState[0];
    const optionsAC = context.optionsACState[0];
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const model = 'super_projeto';
    const objTitleField = 'nome';
    const basePath = '/institucional/';
    const fieldsLayoutAll = [
        {
            rows: ['nome', 'anotacoes'],
        },
        {
            title: 'Projetos',
            CustomElement: (
                <SuperProjetoRelatedProjeto
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label=""
                />
            ),
        },
    ];
    const fieldsLayout = params.objId !== 'novo' ? fieldsLayoutAll : [fieldsLayoutAll[0]];

    const loadAllData = () => {
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Novo Super Projeto' : 'Editar Super Projeto',
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            control={control}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}
