import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function SuperProjetoRelatedProjeto(props) {
    // Specific infos:
    const relatedModel = 'projeto';
    const columnFields = ['id', 'nome', 'sigla', 'anotacoes'];
    const creatableFields = [];
    const minWidthFields = { anotacoes: 350 };
    const indexField = 'nome';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            minWidthFields={minWidthFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
