import React, { useEffect } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';

export default function GenericSettings() {
    const params = useParams();
    /**
     * @type object
     */
    const context = useOutletContext();
    const {
        loadSinglePageData,
        onSubmit,
        onEditModel,
        onDeleteModel,
        onEditRelatedModelSave,
        onDeleteRelatedModel,
    } = context;
    const pageBasics = context.pageState[0];
    const pageForm = context.pageFormState[0];
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const objTitleField = 'nome';
    const fieldsLayout = [];

    const loadAllData = () => {
        loadSinglePageData({
            model: params.model,
            objTitleField,
            objId: params.objId,
            basePath: '/settings/',
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        loadAllData();
    }, [params.objId]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Novo' : 'Editar',
    };

    const action = isTmpId(pageBasics.id) ? 'Registrar' : 'Editar';

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === params.model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons
                        {...formButtonProps}
                        title={`${action} ${
                            pageForm.modelOptions ? pageForm.modelOptions.verbose_name : ''
                        }`}
                    />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            control={control}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={null}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}
