import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedAcessoExtrativismo(props) {
    // Specific infos:
    const relatedModel = 'acesso_extrativismo';
    const columnFields = [
        'id',
        'acesso_extrativismo_lugar',
        'distancia',
        'tipos_bens_naturais',
        'responsavel_coleta',
        'condicoes_acesso',
        'forma_coleta',
        'tem_conflitos',
        'conflitos',
    ];
    const creatableFields = [];
    const indexField = 'acesso_extrativismo_lugar';
    const minWidthFields = {
        conflitos: 200,
        responsavel_coleta: 200,
        tipos_bens_naturais: 200,
        condicoes_acesso: 200,
    };
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
