import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function OrganizacaoRelatedAgricultora(props) {
    // Specific infos:
    const relatedModel = 'agricultora';
    const columnFields = [
        'id',
        'codigo',
        'nome',
        'comunidade',
        'telefone',
        'caderneta_lancamentos_count',
        'caderneta_lancamentos_valor_total',
    ];
    const creatableFields = [];
    const minWidthFields = { is_admin: 200, comunidade: 300 };
    const indexField = 'nome';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = {
        rows: [
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos de caderneta: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_valor_total',
                prefix: 'Valor total lançado: R$',
            },
        ],
        severity: 'info',
    };

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            minWidthFields={minWidthFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
