import React from 'react';
// import DoneAllIcon from '@mui/icons-material/DoneAll';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
// import StorefrontIcon from '@mui/icons-material/Storefront';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SettingsIcon from '@mui/icons-material/Settings';
// import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

// import * as Page from './pages';

const PAGES = {
    home: {
        title: 'Início',
        home: true,
        skipRouter: true,
        permission: null,
    },
    institucional: {
        title: 'Institucional',
        icon: <Inventory2OutlinedIcon />,
        permission: 'is_admin_some_projeto_or_organizacao',
        subPages: {
            super_projeto: { title: 'Super Projetos', permission: 'is_superuser' },
            projeto: { title: 'Projetos', permission: 'is_superuser' },
            organizacao: { title: 'Organizações', permission: 'is_admin_projetos' },
            usuaria: {
                title: 'Técnicas e Coordenadoras',
                permission: 'is_admin_some_projeto_or_organizacao',
            },
        },
    },
    cadernetas: {
        title: 'Cadernetas',
        icon: <GroupOutlinedIcon />,
        directMenu: true,
        subPages: {
            agricultora: { title: 'Agricultoras', permission: null },
            caderneta_lancamento: { title: 'Cadernetas', permission: null },
            produto: { title: 'Produtos', permission: 'is_admin_some_projeto_or_organizacao' },
            questionario: { title: 'Questionários', permission: null },
        },
    },
};

export default PAGES;
