const SETTINGS = {
    HOME: 'https://api.cadernetas.eita.org.br',
    METABASE: {
        URL: 'https://metabase.cadernetas.eita.org.br',
        SECRET_KEY: 'ce51bed1126d66c93867dc46a9c6d0a1d716a292156321be22ca64871c7aa18a',
        EMAIL: 'alan@eita.org.br'
    }
};

export default SETTINGS;
