import React, { useEffect } from 'react';
import {
    useParams,
    useOutletContext,
    Navigate,
    useNavigation,
    useNavigate,
} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isTmpId, FormBySchema } from 'drf-react-by-schema';

import FormButtons from '../components/FormButtons';

export default function Usuaria() {
    const params = useParams();
    const navigate = useNavigate();
    /**
     * @type object
     */
    const context = useOutletContext();
    const {
        usuaria,
        loadSinglePageData,
        onSubmit,
        onEditModel,
        onDeleteModel,
        onEditRelatedModelSave,
        onDeleteRelatedModel,
    } = context;
    const pageBasics = context.pageState[0];
    const pageForm = context.pageFormState[0];
    const optionsAC = context.optionsACState[0];
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(pageForm.validationSchema),
    });

    const model = 'usuaria';
    const objTitleField = 'first_name';
    const basePath = '/institucional/';
    const fieldsLayoutAll = [
        {
            rows: ['username', ['first_name', 'last_name'], ['email', 'sexo'], 'anotacoes'],
        },
        /*
        {
            title: 'Projetos que integra',
            CustomElement: (
                <UsuariaRelatedProjeto
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                    label="Projetos que integra"
                />
            ),
        },
        {
            title: 'Organizações que integra',
            CustomElement: (
                <UsuariaRelatedOrganizacao
                    id={pageBasics.id}
                    onEditModel={onEditModel}
                    onDeleteRelatedModel={onDeleteRelatedModel}
                />
            ),
        },
        */
    ];
    const fieldsLayout = params.objId !== 'novo' ? fieldsLayoutAll : [fieldsLayoutAll[0]];

    const loadAllData = () => {
        loadSinglePageData({
            model,
            objTitleField,
            objId: params.objId,
            basePath,
        }).then((values) => {
            reset(values);
        });
    };

    useEffect(() => {
        if (params.objId === 'novo') {
            navigate('/signup');
            return;
        }
        loadAllData();
    }, [params.objId]);

    const formButtonProps = {
        objId: params.objId || null,
        onDeleteModel,
        formDisabled: !isDirty,
        title: isTmpId(pageBasics.id) ? 'Nova Técnica' : 'Editar Técnica',
    };

    return (
        <>
            {pageForm.schema === false && <Navigate to="/login" replace />}
            {pageBasics.model === model && pageForm.initialValues && control && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormButtons {...formButtonProps} />
                    <Box>
                        <FormBySchema
                            fieldsLayout={fieldsLayout}
                            control={control}
                            errors={errors}
                            schema={pageForm.schema}
                            optionsAC={optionsAC}
                            onEditModel={onEditModel}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Box>
                    <FormButtons {...formButtonProps} bottom={true} />
                </form>
            )}
        </>
    );
}
