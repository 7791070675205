import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import GenericPageList from '../pages/GenericPageList';

export default function AgricultoraList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    let columnFields = ['codigo', 'nome'];
    if (usuaria.is_superuser || usuaria.organizacoes.length > 1 || usuaria.projetos.length > 0) {
        columnFields.push('organizacao');
    }
    if (usuaria.is_superuser || usuaria.projetos.length > 1) {
        columnFields.push('projeto');
    }
    columnFields = [
        ...columnFields,
        'uf',
        'municipio',
        'comunidade',
        'caderneta_lancamentos_count',
        'caderneta_lancamentos_valor_total',
    ];

    const minWidthFields = { organizacao: 200, projeto: 150, produto: 150, comunidade: 200 };
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos de caderneta: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_valor_total',
                prefix: 'Valor total lançado: R$',
            },
        ],
        severity: 'info',
    };
    const customColumnOperations = (column) => {
        if (
            column.field === 'organizacao' &&
            (usuaria.is_superuser || usuaria.organizacoes.length > 1)
        ) {
            column.renderCell = (params) =>
                params.row.organizacao ? (
                    <Link to={`/institucional/organizacao/${params.row.organizacao.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        if (column.field === 'projeto' && (usuaria.is_superuser || usuaria.projetos.length > 1)) {
            column.renderCell = (params) =>
                params.row.projeto ? (
                    <Link to={`/institucional/projeto/${params.row.projeto.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        return column;
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
            customColumnOperations={customColumnOperations}
        />
    );
}
