import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedPropriedade(props) {
    // Specific infos:
    const relatedModel = 'questionario_propriedade';
    const columnFields = ['id', 'propriedade', 'vinculo_propriedade'];
    const creatableFields = ['vinculo_propriedade'];
    const indexField = 'propriedade';
    const minWidthFields = { vinculo_propriedade: 300 };
    const addExistingModel = null;
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
