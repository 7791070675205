import React, { useEffect } from 'react';
import {
    useNavigate,
    useLocation,
    useOutletContext,
    Outlet,
    Link,
    useParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Layout } from '../styles';

export default function InstitucionalIndex() {
    const defaultModel = 'projeto';
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const page = pathParts[1] || null;
    const model = pathParts[2] || null;
    /**
     * @type object
     */
    const outletContext = useOutletContext();
    const context = {
        ...outletContext,
        model,
        indexFieldBasePath: `/${page}/${model}/`,
    };
    const pageBasics = context.pageState[0];
    const params = useParams();

    const handleTabsChange = (event, newModel) => {
        navigate(`${newModel}`);
    };

    useEffect(() => {
        if (!model) {
            navigate(defaultModel);
        }
    }, [location]);

    let tabs = [];

    if (outletContext.usuaria.is_superuser) {
        tabs = [
            {
                label: 'Super Projetos',
                value: 'super_projeto',
            },
            {
                label: 'Projetos',
                value: 'projeto',
            },
            {
                label: 'Organizações',
                value: 'organizacao',
            },
            {
                label: 'Técnicas e Coordenadoras',
                value: 'usuaria',
            },
        ];
    } else if (outletContext.usuaria.is_admin_projetos) {
        tabs = [
            {
                label: 'Organizações',
                value: 'organizacao',
            },
            {
                label: 'Técnicas e Coordenadoras',
                value: 'usuaria',
            },
        ];
    } else if (outletContext.usuaria.is_admin_organizacoes) {
        tabs = [
            {
                label: 'Técnicas e Coordenadoras',
                value: 'usuaria',
            },
        ];
    }

    return (
        <>
            {model && !params.objId && (
                <>
                    <Box sx={Layout.flexRowGrow}>
                        <Typography variant="h5">{context.PAGES[page].title}</Typography>
                        <Box sx={Layout.flexRow}>
                            <Link to={`${model}/novo`}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    sx={{ alignSelf: 'stretch' }}
                                    startIcon={<AddCircleOutlineIcon />}>
                                    Adicionar
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Box>
                        <Tabs value={model} onChange={handleTabsChange} aria-label="Tabs">
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </Tabs>
                        <input value="super_projeto" type="hidden" />
                        <input value="projeto" type="hidden" />
                        <input value="organizacao" type="hidden" />
                        <input value="usuaria" type="hidden" />
                    </Box>
                </>
            )}
            <Outlet context={context} />
        </>
    );
}
