import CONSTANTS from '../constants';
import PAGES from '../pageTree';

export const reducer = (state, newState) => {
    return { ...state, ...newState };
};

export const getPageTitle = (location, objId) => {
    const title = 'Cadernetas Agroecológicas';
    const locationParts = location.pathname.split('/');
    const pageKey = locationParts.length > 1 ? locationParts[1] : '';
    if (!pageKey || !Object.prototype.hasOwnProperty.call(PAGES, pageKey)) {
        return title;
    }
    const pageTitle = PAGES[pageKey].title;
    const subPageKey = locationParts.length > 2 ? locationParts[2] : '';
    if (
        !subPageKey ||
        !PAGES[pageKey].subPages ||
        !Object.prototype.hasOwnProperty.call(PAGES[pageKey].subPages, subPageKey)
    ) {
        return `${pageTitle} :: ${title}`;
    }
    const subPageInfo = PAGES[pageKey].subPages[subPageKey];
    const subPageTitle = subPageInfo.title;
    if (objId) {
        const action = objId === 'novo' ? 'Criação' : 'Edição';
        return pageKey === subPageKey
            ? `${subPageTitle} (${action}) :: ${title}`
            : `${subPageTitle} (${action}) :: ${pageTitle} :: ${title}`;
    }
    return pageKey === subPageKey
        ? `${subPageTitle} :: ${title}`
        : `${subPageTitle} :: ${pageTitle} :: ${title}`;
};
