import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { buildGenericYupValidationSchema, FieldBySchema, useAPIWrapper } from 'drf-react-by-schema';

import { Layout } from '../styles';
import FormButtons from './FormButtons';

export default function NewUsuariaForm() {
    const navigate = useNavigate();
    /**
     * @type object
     */
    const context = useOutletContext();
    const { handleLoading } = context;
    const pageBasics = context.pageState[0];
    const [schema, setSchema] = useState(null);
    const [validationSchema, setValidationSchema] = useState(null);
    const [optionsAC, setOptionsAC] = useState(null);
    const { getAutoComplete, usuaria, signUp, getSignUpOptions } = useAPIWrapper();
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
    });
    const showOrganizacaoField =
        !usuaria.is_superuser && (usuaria.is_admin_projetos || usuaria.is_admin_organizacoes);
    const formButtonProps = {
        objId: null,
        onDeleteModel: null,
        formDisabled: !isDirty,
        title: 'Nova Usuária',
        backTarget: '/institucional/usuaria',
    };

    const getSchemaAsync = async () => {
        const data = await getSignUpOptions();
        if (data !== false) {
            const emptyUsuaria = {
                username: '',
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                confirm_password: '',
            };
            if (showOrganizacaoField) {
                emptyUsuaria.organizacao = null;
                data.organizacao.required = true;
                if (!usuaria.is_admin_projetos && usuaria.organizacoes.length === 1) {
                    emptyUsuaria.organizacao = usuaria.organizacoes[0].organizacao;
                    data.organizacao.disabled = true;
                }
                const organizacaoOptions = await getAutoComplete('organizacao');
                setOptionsAC({ organizacao: organizacaoOptions });
            }
            reset(emptyUsuaria);
            const yupSchema = buildGenericYupValidationSchema({
                schema: data,
                data: emptyUsuaria,
                extraValidators: {
                    confirm_password: Yup.string().oneOf(
                        [Yup.ref('password'), null],
                        'A senha não está igual'
                    ),
                },
            });

            setSchema(data);
            setValidationSchema(yupSchema);
            handleLoading(false);
            return;
        }
        console.log('couldnt load schema!');
        handleLoading(false);
    };

    const onSubmit = async (data, e) => {
        const response = await signUp(data);

        // @ts-ignore
        if (response !== false) {
            const buttonType = e.nativeEvent.submitter.name || 'exitOnSave';
            const basePath = `${pageBasics.basePath}${pageBasics.formPath || pageBasics.model}`;

            if (buttonType === 'exitOnSave') {
                navigate('/institucional/usuaria', { state: 'reload' });
                return;
            }

            if (buttonType === 'createNewOnSave') {
                navigate('/institucional/usuaria/novo');
                return;
            }

            // @ts-ignore
            navigate(`/institucional/usuaria/${response.id}`);
        }

        return response;
    };

    useEffect(() => {
        getSchemaAsync();
    }, []);

    if (!schema || !validationSchema) {
        return <></>;
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormButtons {...formButtonProps} />
            <Card sx={Layout.formCard}>
                <CardContent>
                    <FieldBySchema
                        name="username"
                        control={control}
                        errors={errors}
                        schema={schema}
                    />
                    <Box sx={Layout.inLineForm}>
                        <FieldBySchema
                            name="first_name"
                            control={control}
                            errors={errors}
                            schema={schema}
                        />
                        <FieldBySchema
                            name="last_name"
                            control={control}
                            errors={errors}
                            schema={schema}
                        />
                    </Box>
                    <FieldBySchema name="email" control={control} schema={schema} errors={errors} />
                    {showOrganizacaoField && (
                        <FieldBySchema
                            name="organizacao"
                            control={control}
                            schema={schema}
                            errors={errors}
                            optionsAC={optionsAC}
                        />
                    )}
                    <FieldBySchema
                        name="password"
                        control={control}
                        schema={schema}
                        errors={errors}
                    />
                    <FieldBySchema
                        name="confirm_password"
                        control={control}
                        schema={schema}
                        errors={errors}
                    />
                </CardContent>
            </Card>
            <FormButtons {...formButtonProps} bottom={true} />
        </form>
    );
}

NewUsuariaForm.propTypes = {
    schema: PropTypes.object,
    control: PropTypes.object,
    errors: PropTypes.object,
    setValue: PropTypes.func,
};
