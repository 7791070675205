import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthRoute from './components/AuthRoute';
import * as Page from './pages';
import { DRFReactBySchemaProvider, slugToCamelCase } from 'drf-react-by-schema';
import theme from './styles/theme';
import CONSTANTS from './constants';
import PAGES from './pageTree';
import NewUsuariaForm from './components/NewUsuariaForm';

export default function App() {
    return (
        <DRFReactBySchemaProvider
            serverEndPoint={{
                url: CONSTANTS.API.url,
                apiTokenUrl: CONSTANTS.API.apiTokenUrl,
                signUp: CONSTANTS.API.signUp,
            }}
            theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route element={<AuthRoute />}>
                        <Route path="/" element={<Page.PageContentHandler />}>
                            {Object.entries(PAGES).map((entry) => {
                                const [key, pageInfo] = entry;
                                if (pageInfo.skipRouter) {
                                    return <React.Fragment key={key}></React.Fragment>;
                                }
                                const componentName = slugToCamelCase(`_${key}`);
                                const PageIndex = Page[`${componentName}Index`];
                                return (
                                    <Route key={key} path={key} element={<PageIndex />}>
                                        {Object.entries(pageInfo.subPages).map((subPageEntry) => {
                                            const subPageKey = subPageEntry[0];
                                            let subComponentName = slugToCamelCase(
                                                `_${subPageKey}`
                                            );
                                            const PageList = Page[`${subComponentName}List`];
                                            const PageDetail = Page[subComponentName];
                                            return (
                                                <Route
                                                    key={`${key}-${subPageKey}`}
                                                    path={subPageKey}
                                                    element={<PageList />}>
                                                    {PageDetail && (
                                                        <Route
                                                            path=":objId"
                                                            element={<PageDetail />}
                                                        />
                                                    )}
                                                </Route>
                                            );
                                        })}
                                    </Route>
                                );
                            })}
                            <Route path="signup" element={<NewUsuariaForm />} />
                            <Route path="settings" element={<Page.GenericSettingsIndex />}>
                                <Route path=":model" element={<Page.GenericSettingsList />}>
                                    <Route path=":objId" element={<Page.GenericSettings />} />
                                </Route>
                            </Route>
                            <Route
                                path="*"
                                element={
                                    <main>
                                        <p>Página não encontrada!</p>
                                    </main>
                                }
                            />
                            <Route index element={<Page.Home />} />
                        </Route>
                    </Route>
                    <Route path="/login" element={<Page.Login />} />
                </Routes>
            </BrowserRouter>
        </DRFReactBySchemaProvider>
    );
}
