import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedParente(props) {
    // Specific infos:
    const relatedModel = 'questionario_parente';
    const columnFields = ['id', 'parente', 'parentesco'];
    const creatableFields = ['parente', 'parentesco'];
    const indexField = 'parente';
    const minWidthFields = { parentesco: 300 };
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            minWidthFields={minWidthFields}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
