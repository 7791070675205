import React from 'react';

import GenericRelatedModelList from './GenericRelatedModelList';

export default function QuestionarioRelatedAcessoAgua(props) {
    // Specific infos:
    const relatedModel = 'acesso_agua';
    const columnFields = [
        'id',
        'acesso_agua_infraestrutura',
        'distancia_infraestrutura',
        'distancia_infraestrutura_textual',
        'uso',
        'quantidade',
        'acesso_agua_qualidade',
        'agricultora_responsavel_buscar',
        'responsavel_buscar',
        'origem_agua',
        'principal_para_consumo',
        'principal_para_producao',
        'paga_agua',
        'valor_agua',
        'anotacoes',
    ];
    const creatableFields = ['acesso_agua_infraestrutura', 'parentesco', 'acesso_agua_qualidade'];
    const indexField = 'acesso_agua_infraestrutura';
    const addExistingModel = '';
    const onProcessRow = null;
    const sumRows = null;

    return (
        <GenericRelatedModelList
            relatedModel={relatedModel}
            columnFields={columnFields}
            creatableFields={creatableFields}
            sumRows={sumRows}
            onProcessRow={onProcessRow}
            indexField={indexField}
            addExistingModel={addExistingModel}
            noCardWrapper={true}
            paginationMode="server"
            {...props}
        />
    );
}
