import React from 'react';

import GenericPageList from '../pages/GenericPageList';

export default function ProdutoList() {
    const columnFields = [
        'nome',
        'produto_classe',
        'produto_grupo',
        'produto_origem',
        'produto_processamento',
        'caderneta_lancamentos_count',
        'caderneta_lancamentos_valor_total',
    ];

    const minWidthFields = {
        produto_grupo_display: 300,
        caderneta_lancamento_count: 200,
        caderneta_lancamentos_valor_total: 200,
    };
    const indexField = 'nome';
    const sumRows = {
        rows: [
            {
                field: 'caderneta_lancamentos_count',
                prefix: 'Total de lançamentos do produto em cadernetas: ',
                isCount: true,
            },
            {
                field: 'caderneta_lancamentos_valor_total',
                prefix: 'Valor total lançado do produto em cadernetas: R$',
            },
        ],
        severity: 'info',
    };
    const customColumnOperations = null;

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
            customColumnOperations={customColumnOperations}
        />
    );
}
