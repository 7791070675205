import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import GenericPageList from '../pages/GenericPageList';

export default function CadernetaLancamentoList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    let columnFields = ['data', 'codigo', 'agricultora'];
    if (usuaria.is_superuser || usuaria.organizacoes.length > 1) {
        columnFields.push('organizacao');
    }
    if (usuaria.is_superuser || usuaria.projetos.length > 1) {
        columnFields.push('projeto');
    }
    columnFields = [
        ...columnFields,
        'produto',
        'produto_classe',
        'produto_grupo',
        'produto_origem',
        'produto_processamento',
        'unidade_medida',
        'quantidade',
        'valor_unitario',
        'valor_total',
        'caderneta_lancamento_relacao',
    ];

    const minWidthFields = {
        agricultora: 200,
        caderneta_lancamento_relacao: 150,
        organizacao: 200,
        projeto: 150,
        produto: 150,
    };
    const indexField = 'data';
    const sumRows = {
        rows: [
            {
                field: 'valor_total',
                prefix: 'Total: R$ ',
            },
        ],
    };
    const customColumnOperations = (column) => {
        if (column.field === 'agricultora') {
            column.renderCell = (params) =>
                params.row.agricultora ? (
                    <Link to={`/cadernetas/agricultora/${params.row.agricultora.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        if (
            column.field === 'organizacao' &&
            (usuaria.is_superuser || usuaria.organizacoes.length > 1)
        ) {
            column.renderCell = (params) =>
                params.row.organizacao ? (
                    <Link to={`/institucional/organizacao/${params.row.organizacao.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        if (column.field === 'projeto' && (usuaria.is_superuser || usuaria.projetos.length > 1)) {
            column.renderCell = (params) =>
                params.row.projeto ? (
                    <Link to={`/institucional/projeto/${params.row.projeto.id}`}>
                        {params.formattedValue}
                    </Link>
                ) : (
                    <></>
                );
        }
        return column;
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
            hasHeader={true}
            customColumnOperations={customColumnOperations}
        />
    );
}
