import React, { useReducer } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDRFReactBySchema } from 'drf-react-by-schema';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clearJWT } from 'drf-react-by-schema';

import { reducer } from '../utils/utils';
import PAGES from '../pageTree';

export default function TopBar({ usuaria }) {
    const { theme } = useDRFReactBySchema();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const location = useLocation();
    const pageParts = location.pathname.split('/');
    const page = pageParts[1];
    const subPage = pageParts[2];
    const initialSubmenuOpen = {};
    for (const entry of Object.entries(PAGES)) {
        initialSubmenuOpen[entry[0]] = null;
    }
    /**
     * @type object
     */
    const [anchorPageEl, setAnchorPageEl] = useReducer(reducer, initialSubmenuOpen);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageOpen = (submenu, event) => {
        const pageStatus = {};
        for (const key in initialSubmenuOpen) {
            pageStatus[key] = submenu === key ? event.currentTarget : null;
        }
        setAnchorPageEl({ ...pageStatus });
    };
    const handlePageClose = () => {
        setAnchorPageEl({ ...initialSubmenuOpen });
    };

    return (
        <AppBar position="fixed" className="topbar">
            <Toolbar>
                {/* <Button
                    id="newCadernetaLancamento"
                    color="topBarButton"
                    variant="text"
                    size="small"
                    onClick={handleNewCadernetaLancamentoButton}
                    sx={{ ml: 7 }}>
                    Novo lançamento em caderneta
                </Button> */}
                {Object.entries(PAGES).map((entry) => {
                    const [key, pageInfo] = entry;
                    if (
                        !pageInfo.permission ||
                        usuaria.is_superuser ||
                        usuaria[pageInfo.permission]
                    ) {
                        if (pageInfo.home) {
                            return (
                                <Button
                                    key="home"
                                    color={page === '' ? 'topBarButtonSelected' : 'topBarButton'}
                                    variant="text"
                                    size="small"
                                    onClick={() => {
                                        if (page !== '') {
                                            navigate('/');
                                        }
                                    }}
                                    sx={{ mr: 2 }}>
                                    {pageInfo.title}
                                </Button>
                            );
                        }

                        if (pageInfo.directMenu) {
                            return (
                                <React.Fragment key={key}>
                                    {Object.entries(pageInfo.subPages).map((entry) => {
                                        const [subPageKey, subPageInfo] = entry;
                                        if (
                                            !subPageInfo.permission ||
                                            usuaria.is_superuser ||
                                            usuaria[subPageInfo.permission]
                                        ) {
                                            return (
                                                <Button
                                                    key={subPageKey}
                                                    color={
                                                        subPage === subPageKey &&
                                                        pageParts.length < 4
                                                            ? 'topBarButtonSelected'
                                                            : 'topBarButton'
                                                    }
                                                    variant="text"
                                                    size="small"
                                                    onClick={() => {
                                                        if (
                                                            subPage !== subPageKey ||
                                                            pageParts.length === 4
                                                        ) {
                                                            navigate(`/${key}/${subPageKey}`);
                                                        }
                                                    }}
                                                    sx={{ mr: 2 }}>
                                                    {subPageInfo.title}
                                                </Button>
                                            );
                                        }
                                        return <React.Fragment key={subPageKey}></React.Fragment>;
                                    })}
                                </React.Fragment>
                            );
                        }

                        return (
                            <React.Fragment key={key}>
                                <Button
                                    id={`open-${key}-menu`}
                                    color="topBarButton"
                                    variant="text"
                                    size="small"
                                    aria-controls={anchorPageEl[key] ? `${key}-menu` : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={anchorPageEl[key] ? 'true' : undefined}
                                    onClick={(event) => {
                                        handlePageOpen(key, event);
                                    }}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    sx={{ mr: 2 }}>
                                    {pageInfo.title}
                                </Button>
                                <Menu
                                    id={`${key}-menu`}
                                    anchorEl={anchorPageEl[key]}
                                    open={anchorPageEl[key] !== null}
                                    onClose={handlePageClose}
                                    MenuListProps={{
                                        'aria-labelledby': `open-${key}-menu`,
                                    }}>
                                    {Object.entries(pageInfo.subPages).map((entry) => {
                                        const [subPageKey, subPageInfo] = entry;
                                        if (
                                            !subPageInfo.permission ||
                                            usuaria.is_superuser ||
                                            usuaria[subPageInfo.permission]
                                        ) {
                                            return (
                                                <MenuItem
                                                    key={`${key}/${subPageKey}`}
                                                    component={Link}
                                                    to={`${key}/${subPageKey}`}
                                                    disabled={
                                                        subPage === subPageKey &&
                                                        pageParts.length < 4
                                                    }
                                                    onClick={handlePageClose}>
                                                    {subPageInfo.title}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </Menu>
                            </React.Fragment>
                        );
                    }
                    return <React.Fragment key={key}></React.Fragment>;
                })}
                <Box sx={{ flexGrow: 1 }} />

                <Tooltip title="Configurações">
                    <Button
                        onClick={handleOpen}
                        color="topBarButton"
                        variant="text"
                        size="small"
                        endIcon={<KeyboardArrowDownIcon />}>
                        {usuaria.first_name || usuaria.username}
                    </Button>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <MenuItem
                        component={Link}
                        key="minhaconta"
                        to={`/settings/usuaria/${usuaria.id}`}
                        state={{ title: 'Meu perfil' }}>
                        <ListItemIcon>
                            <Avatar fontSize="small" />
                        </ListItemIcon>
                        Meu perfil
                    </MenuItem>
                    {usuaria.is_superuser && (
                        <MenuItem component={Link} key="configuracoesIndex" to="/settings">
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Configurações
                        </MenuItem>
                    )}
                    <MenuItem
                        key="sair"
                        onClick={() => {
                            clearJWT();
                            navigate('/login');
                        }}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Desconectar
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    usuaria: PropTypes.object,
};
