import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import '../styles/globals.css';
import '../styles/home.css';
import MetabaseAppEmbed from '../components/MetabaseAppEmbed';
import { Layout } from 'src/styles';
import { useAPIWrapper } from 'drf-react-by-schema';

function buildPayload(usuaria) {
    const payload = {
        resource: { dashboard: null },
        params: {},
    };
    if (usuaria.is_superuser) {
        payload.resource.dashboard = 1;
        return { payload, title: 'Relatórios' };
    }
    if (usuaria.projetos.length === 1 && usuaria.organizacoes.length === 0) {
        const projeto = usuaria.projetos[0].projeto.label;
        payload.resource.dashboard = 3;
        payload.params = { projeto };
        return { payload, title: `Relatórios do projeto "${projeto}"` };
    }
    if (usuaria.projetos.length === 0 && usuaria.organizacoes.length === 1) {
        const organizacao = usuaria.organizacoes[0].organizacao.label;
        payload.resource.dashboard = 4;
        payload.params = { organizacao };
        return { payload, title: `Relatórios da organização "${organizacao}"` };
    }
    return { payload, title: null };
}

export default function Home() {
    /**
     * @type object
     */
    const { handleLoading, usuaria } = useOutletContext();
    const { getAutoComplete } = useAPIWrapper();

    const {
        payload: payloadInitial,
        projeto: projetoInitial,
        organizacao: organizacaoInitial,
        title: titleInitial,
    } = buildPayload(usuaria);

    const [payload, setPayload] = useState(payloadInitial);
    const [options, setOptions] = useState({ projeto: null, organizacao: null });
    const [projeto, setProjeto] = useState(projetoInitial);
    const [title, setTitle] = useState(titleInitial);
    const [organizacao, setOrganizacao] = useState(organizacaoInitial);
    const hasDropDowns =
        usuaria.projetos.length > 1 ||
        usuaria.organizacoes.length > 1 ||
        (usuaria.projetos.length === 1 && usuaria.organizacoes.length === 1);

    useEffect(() => {
        if (!payloadInitial.resource.dashboard) {
            handleLoading(false);
        }
        async function loadOptionsAsync() {
            const newOptions = {
                ...options,
            };
            if (usuaria.organizacoes.length > 0 && !options.organizacao) {
                newOptions.organizacao = await getAutoComplete('organizacao');
            }
            if (usuaria.projetos.length > 0 && !options.projeto) {
                newOptions.projeto = await getAutoComplete('projeto');
            }
            setOptions(newOptions);
        }
        if (hasDropDowns) {
            loadOptionsAsync();
        }
    }, [usuaria]);

    return (
        <>
            <Typography variant="h5" sx={{ mb: 3 }}>
                {title}
            </Typography>
            {hasDropDowns && (
                <Box sx={{ ...Layout.flexRow, backgroundColor: '#fff', p: 3 }}>
                    {usuaria.projetos.length > 0 && (
                        <Autocomplete
                            key={'projeto'}
                            options={options.projeto || []}
                            selectOnFocus
                            autoHighlight
                            value={projeto || null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            onChange={(e, value) => {
                                if (!value) {
                                    setProjeto(null);
                                    setOrganizacao(null);
                                    setPayload({
                                        resource: { dashboard: null },
                                        params: {},
                                    });
                                    return;
                                }
                                handleLoading(true);
                                setProjeto(value);
                                setTitle(`Relatórios do projeto "${value.label}"`);
                                setOrganizacao(null);
                                setPayload({
                                    resource: {
                                        dashboard: 3,
                                    },
                                    params: {
                                        projeto: value.label,
                                    },
                                });
                            }}
                            fullWidth
                            sx={{ maxWidth: '50%' }}
                            renderInput={(params) => {
                                return <TextField {...params} label={'Projeto'} margin="normal" />;
                            }}
                        />
                    )}
                    {usuaria.organizacoes.length > 0 && (
                        <Autocomplete
                            key={'organizacao'}
                            options={options.organizacao || []}
                            selectOnFocus
                            autoHighlight
                            value={organizacao || null}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            onChange={(e, value) => {
                                if (!value) {
                                    setProjeto(null);
                                    setOrganizacao(null);
                                    setPayload({
                                        resource: { dashboard: null },
                                        params: {},
                                    });
                                    return;
                                }
                                handleLoading(true);
                                setOrganizacao(value);
                                setTitle(`Relatórios da organização "${value.label}"`);
                                setProjeto(null);
                                setPayload({
                                    resource: {
                                        dashboard: 4,
                                    },
                                    params: {
                                        organizacao: value.label,
                                    },
                                });
                            }}
                            fullWidth
                            sx={{ pl: 3, pr: 3 }}
                            renderInput={(params) => {
                                return (
                                    <TextField {...params} label={'Organizacao'} margin="normal" />
                                );
                            }}
                        />
                    )}
                </Box>
            )}

            {payload.resource.dashboard ? (
                <MetabaseAppEmbed
                    title="Relatórios"
                    className="metabaseDashboard"
                    payload={payload}
                    onFrame={(frameLoaded) => {
                        handleLoading(frameLoaded);
                    }}
                    titled={false}
                />
            ) : (
                <Box
                    sx={{
                        height: 500,
                        backgroundColor: '#fff',
                    }}
                />
            )}
        </>
    );
}
