import React from 'react';
import { useOutletContext } from 'react-router-dom';

import GenericPageList from '../pages/GenericPageList';

export default function UsuariaList() {
    /**
     * @type object
     */
    const { usuaria } = useOutletContext();
    const columnFields = ['first_name', 'last_name', 'email'];
    if (usuaria.is_superuser || usuaria.projetos.length > 1) {
        columnFields.push('projetos_display');
    }
    if (usuaria.is_superuser || usuaria.is_admin_projetos || usuaria.organizacoes.length > 1) {
        columnFields.push('organizacoes_display');
    }
    const minWidthFields = {
        last_name: 200,
        email: 200,
        projetos_display: 350,
        organizacoes_display: 350,
    };
    const indexField = 'first_name';
    const sumRows = {
        rows: [],
        severity: 'info',
    };

    return (
        <GenericPageList
            columnFields={columnFields}
            indexField={indexField}
            sumRows={sumRows}
            minWidthFields={minWidthFields}
        />
    );
}
